/* eslint-disable */
import { useQuery } from "@apollo/react-hooks";
import Box from "@terminal-packages/ui/core/Box";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import List from "@terminal-packages/ui/core/List";
import get from "lodash/get";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { newApiClient } from "src/clients";
import { Overlay, OVERLAY_TYPES } from "src/components/no-data-overlay";
import { LIMIT_SITES_PAGINATION } from "src/constants";
import { useInfiniteScroll } from "src/hooks";
import getAccountIdFromUrl from "src/utils/get-account-id-from-url";

import SiteRow from "./components/SiteRow";
import SiteRowsSkeleton from "./components/SiteRowsSkeleton";
import GET_SITES_BY_TEAM_QUERY from "./graphql/queries/get-sites-by-team";
import presenter from "./presenter";
import useStyles from "./styles";

const updateCache = (previousResult, newResult) => {
  const previousSites = get(previousResult, "getSitesByTeam.sites", []);
  const newSites = get(newResult, "getSitesByTeam.sites", []);
  const newNextToken = get(newResult, "getSitesByTeam.nextToken", null);

  return {
    getSitesByTeam: {
      sites: [...previousSites, ...newSites],
      nextToken: newNextToken,
      __typename: "SiteConnection",
    },
  };
};

const TeamSites = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();
  const teamId = getAccountIdFromUrl();

  const { loading, data, fetchMore } = useQuery(GET_SITES_BY_TEAM_QUERY, {
    client: newApiClient,
    variables: {
      teamId: match.params.teamId,
      limit: LIMIT_SITES_PAGINATION,
    },
    notifyOnNetworkStatusChange: true,
  });

  const nextToken = get(data, "getSitesByTeam.nextToken");

  useInfiniteScroll({
    nextToken,
    updateCache,
    fetchMore,
  });

  const classes = useStyles();

  const getSitesContent = () => {
    if (loading && !data) {
      return (
        <List striped>
          {Array(4)
            .fill()
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SiteRowsSkeleton key={`skeleton-${index}`} />
            ))}
        </List>
      );
    }

    const i18n = {
      at: t("sites.at"),
      ipfs: t("sites.ipfs"),
      github: t("sites.github"),
      ownedBy: t("sites.ownedBy"),
      dfinity: t("sites.dfinity"),
      andSymbol: t("sites.andSymbol"),
      deployFrom: t("sites.deployFrom"),
      addNewSite: t("sites.addNewSite"),
      lastPublished: t("sites.lastPublished"),
      deployInProgress: t("sites.deployInProgress"),
      failedDeploy: t("sites.failedDeploy"),
      deployLocally: t("sites.deployLocally"),
    };

    const sites = get(data, "getSitesByTeam.sites", []);

    return (
      <div id="test-sites">
        {sites?.length === 0 && <SiteRow isPlaceholder i18n={i18n} />}
        <List striped>
          {sites.map((_site) => {
            const site = presenter.getSite(_site);

            return <SiteRow key={_site.id} i18n={i18n} site={site} />;
          })}
          {nextToken && loading && <SiteRowsSkeleton amountRows={1} />}
        </List>
      </div>
    );
  };

  const showContent = loading || !!get(data, "getSitesByTeam.sites.length");

  useEffect(() => {
    window.analytics.page("Sites", {
      path: location.pathname,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Overlay
        type={OVERLAY_TYPES.SITES_LIST}
        isContentActive={showContent}
      />
      <Box padding="15px 0 25px 0">
        <div className={classes.buttonContainer}>
          <Link
            to={`/start/connect-repository?accountId=${teamId}`}
            className={classes.linkButton}
          >
            <GenericButton buttonVariant="primary" className={classes.button}>
              {t("sites.addNewSite")}
            </GenericButton>
          </Link>
        </div>
        {getSitesContent()}
      </Box>
    </>
  );
};

export default TeamSites;
