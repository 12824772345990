// @ts-nocheck
import { Grid, Link } from "@fleekhq/react-drip";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { BannerContainer, BannerText, Header, LogoImg } from "./auth-header.styled";

export const AuthHeader = forwardRef<any, any>(function AuthHeader(props, ref) {
  const { t } = useTranslation();

  return (
    <>
      <BannerContainer>
        <BannerText>
         ⚡ The Fleek.xyz closed Alpha is live! Get early acces to test the new app! Read more&nbsp;
          <Link isExternal href="https://blog.fleek.xyz/post/fleekco-users-and-alpha-fleekxyz/" css={{ color: 'white', textDecoration: 'underline', fontWeight: '600' }}>here</Link>. ⚡
        </BannerText>
      </BannerContainer>
      <Header ref={ref} {...props}>
        <LogoImg
          src="https://storage.googleapis.com/terminal-assets/images/fleek/fleek-logo.png"
          alt="fleek-logo"
        />

        <Grid css={{ gridAutoFlow: "column", gap: "$8", color: "$slate11" }}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.fleek.co/"
          >
            {t("auth.header.blog") as string}
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/fleek"
          >
            {t("auth.header.community") as string}
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.fleek.co/"
          >
            {t("auth.header.docs") as string}
          </Link>
        </Grid>
      </Header>
    </>
  );
});
