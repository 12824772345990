import gql from 'graphql-tag';

export const MEMBERSHIP_INFO = gql`
  fragment MembershipInfo on Membership {
    teamId
    teamName
    createdAt
    accessLevel
    migrationStatus
  }
`;
