import Typography from "@material-ui/core/Typography";
import NavMenu from "@terminal-packages/ui/core/NavMenu";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useTeam } from "src/store/AuthContext";
import { useAuth } from "src/store/AuthContext";
import { url } from "src/utils";
import getAccountIdFromUrl from "src/utils/get-account-id-from-url";

import TeamSelector from "../TeamSelector";
import UserMenu from "../UserMenu";
import useConfig from "./config";
import useStyles from "./styles";

const Sidebar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const teamId = getAccountIdFromUrl();
  const { currentUser } = useAuth();
  const { migrationStatus } = useTeam();
  const items = useConfig({ t, location, teamId, isNewUser: currentUser.isNewUser, migrationStatus });

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <Link to={url.buildUrl(null, `/teams/${teamId}/sites`)}>
          <img
            alt="fleek-logo"
            className={classes.logo}
            src="https://storage.googleapis.com/terminal-assets/images/fleek/fleek-logo.png"
          />
        </Link>
      </div>
      <div className={classes.teamContainer}>
        <Typography variant="body2" className={classes.teamLabel}>
          {t("layout.sidebar.team")}
        </Typography>
        <TeamSelector />
      </div>
      <NavMenu items={items} />
      <div className={classes.userMenuContainer}>
        <UserMenu />
      </div>
    </div>
  );
};

export default Sidebar;
