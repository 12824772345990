import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import PropTypes from "prop-types";
import { newApiClient } from "src/clients";
import config from "src/config";
import { GET_PLANS } from "src/views/Teams/Billing/graphql/queries";
import PickPlan from "src/views/Teams/shared/components/PickPlan";

const ChoosePlan = ({ selectPlan }) => {
  const { data: plansData } = useQuery(GET_PLANS, {
    client: newApiClient,
  });
  const availablePlans = get(plansData, "getPlans", []);

  return (
    <PickPlan
      onClickPlan={selectPlan}
      plans={availablePlans}
      currentPlan={{
        id: "basic_plan",
        priceMonthly: 0
      }}
      supportEmail={config.supportEmail}
    />
  );
};

ChoosePlan.propTypes = {
  selectPlan: PropTypes.func.isRequired,
};

export default ChoosePlan;
