import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { faCheckDouble } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonWithIcon } from "@terminal-packages/ui/core/Buttons";
import GenericButton from "@terminal-packages/ui/core/Buttons/GenericButton";
import CardTitled from "@terminal-packages/ui/core/CardTitled";
import List from "@terminal-packages/ui/core/List";
import ListItem from "@terminal-packages/ui/core/ListItem";
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { newApiClient } from "src/clients";
import { MainContent } from "src/components";
import config from "src/config";
import { projectAuth } from "src/firebase";
import { GENERATE_MIGRATION_TOKEN } from "src/graphql/mutations";
import { GET_MEMBERSHIPS } from "src/graphql/queries";

import useStyles from "./styles";

const Migration = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [teamMigrations, setTeamMigrations] = useState(new Map());
  const [disableActions, setDisableActions] = useState(false);

  const [generateMigrationToken] = useMutation(GENERATE_MIGRATION_TOKEN, {
    client: newApiClient,
  });

  const handleCreateMigrationToken = async () => {
    const teamsToMigrate = Array.from(teamMigrations.entries())
      .filter(([, value]) => value.value === true)
      .map(([key]) => key);

    try {
      const res = await generateMigrationToken({
        variables: {
          teamIds: teamsToMigrate,
        },
      });

      setDisableActions(false);

      return res.data.generateMigrationToken;
    } catch (error) {
      console.error("Cound't create a migration token for the selected teams", error);

      return null;
    }
  };

  const { loading } = useQuery(GET_MEMBERSHIPS, {
    client: newApiClient,
    skip: !projectAuth.currentUser,
    onCompleted: (data) => {
      const teamEntries = data?.getMemberships?.memberships || [];
      const teamMigrationObject = teamEntries.filter((membership) => membership.accessLevel === 'admin').map((membership) => {
        return (
          [membership.teamId, { value: false, name: membership.teamName, migrationStatus: membership.migrationStatus }]
        )
      });
      setTeamMigrations(new Map(teamMigrationObject));
    },
  });

  const handleBeginMigration = async () => {
    setDisableActions(true);
    const migrationToken = await handleCreateMigrationToken();

    if (migrationToken) {
      const migrationTab = window.open(`${config.migration.url}/migration/?migrationToken=${migrationToken}`, '_blank');
      migrationTab.focus();
    }
  }

  const handleSelectAll = () => {
    const newTeamMigrations = new Map();
    teamMigrations.forEach((value, key) => {
      newTeamMigrations.set(key, { ...value, value: value.migrationStatus ? false : true });
    });
    setTeamMigrations(newTeamMigrations);
  };

  const handleSelectTeam = (teamId) => {
    const updatedValue = { ...teamMigrations.get(teamId), value: !teamMigrations.get(teamId).value };
    const newTeamMigrations = new Map(teamMigrations);
    newTeamMigrations.set(teamId, updatedValue);
    setTeamMigrations(newTeamMigrations);
  }

  const hasTeamsSelected = teamMigrations.values().some((migration) => migration.value);

  const SelectAllButton = (
    <ButtonWithIcon
      icon={<FontAwesomeIcon icon={faCheckDouble} size="lg" />}
      classes={{
        iconAndTextContainer: classes.editIconAndTextContainer,
        iconContainer: classes.editIcon,
      }}
      onClick={handleSelectAll}
      disabled={loading || disableActions}
    >
      {t("migration.selectAll")}
    </ButtonWithIcon>
  );

  return (
    <MainContent>
      <Helmet>
        <title>{t("migration.pageTitle")}</title>
      </Helmet>
      <br />
      <section className={classes.root}>
        <Typography variant="body1">
          <Box component="span" fontWeight={500}>
            {t("migration.title")}
          </Box>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("migration.description")}
        </Typography>
        <br />
        <CardTitled
          mainContent={t("teamSettings.teamDetails.card.title")}
          secondaryContent={SelectAllButton}
          classes={{
            content: classes.content,
          }}
        >
          {loading ? <LoadingTeamList /> : (
            <TeamList teamMigrations={teamMigrations} handleSelectTeam={handleSelectTeam} disabled={disableActions} />
          )}
          <br />
          <GenericButton buttonVariant={!loading && hasTeamsSelected && !disableActions ? "primary" : 'disabled'} className={classes.submitButton} disabled={loading || !hasTeamsSelected || disableActions} onClick={handleBeginMigration}>
            {t("migration.submit")}
          </GenericButton>
        </CardTitled>
      </section>
    </MainContent>
  )
}

const LoadingTeamList = () => {
  const classes = useStyles();

  return (
    <List striped>
      <ListItem className={classes.item}>
        <Checkbox color="primary" disabled inputProps={{ 'aria-label': 'controlled' }} />
        <Skeleton
          width={240}
          height={20}
          variant="rect"
          animation="wave"
          classes={{
            root: classes.skeletonRoot,
          }}
        />
      </ListItem>
      <ListItem className={classes.item}>
        <Checkbox color="primary" disabled inputProps={{ 'aria-label': 'controlled' }} />
        <Skeleton
          width={240}
          height={20}
          variant="rect"
          animation="wave"
          classes={{
            root: classes.skeletonRoot,
          }}
        />
      </ListItem>
    </List>
  )
}

const TeamList = ({ teamMigrations, handleSelectTeam, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (teamMigrations.size === 0) {
    return (
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.text}
      >
        {t("migration.empty")}
      </Typography>
    )
  }

  return (
    <List striped>
      {Array.from(teamMigrations).map(([key, value]) => {
        const isMigrated = value.migrationStatus;

        return (
          <ListItem className={classes.item} key={key}>
            <Checkbox color="primary" onChange={() => handleSelectTeam(key)} checked={value.value} inputProps={{ 'aria-label': 'controlled' }} disabled={disabled || isMigrated} />
            <Typography
              variant="body2"
              color={isMigrated ? "textSecondary" : "textPrimary"}
              className={classes.key}
            >
              {value.name}
            </Typography>
          </ListItem>
        )
      })}
    </List>
  )
}

export default Migration
