import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MainContent, Overlay, OVERLAY_TYPES } from "src/components";
import { useTeam } from "src/store/AuthContext";

import { TeamDetails } from "./components";

const TeamSettings = () => {
  const { t } = useTranslation();
  const { migrationStatus } = useTeam();

  return (
    <MainContent>
      <Helmet>
        <title>{t("teamSettings.pageTitle")}</title>
      </Helmet>

      <br />

      <Overlay
        type={OVERLAY_TYPES.MIGRATED_TEAM}
        isContentActive={!migrationStatus}
      />
      <TeamDetails />
    </MainContent>
  );
};

export default TeamSettings;
