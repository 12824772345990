import Typography from "@material-ui/core/Typography";
import { Trans } from "react-i18next";

import useStyles from "./styles";

const TopBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
      ⚠️ Update ⚠️  - &nbsp;
        <Trans
          i18nKey="topBanner.title"
          components={<a
            key="-"
            className={classes.link}
            href="https://blog.fleek.co/posts/fleek-co-gateway-storage-url-deprecation"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>}
        />
      </Typography>
    </div>
  );
};

export default TopBanner;
