import ListItem from "@terminal-packages/ui/core/ListItem";
import get from "lodash/get";

import { BucketType } from "../..";
import DomainInfo from "../DomainInfo";
import DomainOptions from "../DomainOptions";
import DomainSkeleton from "../DomainSkeleton";
import DomainStatus from "../DomainStatus";
import useStyles from "./styles";

export type DomainProps = {
  isLoading: boolean;
  bucket?: BucketType | null;
  verifyDns: () => void;
};

const Domain = ({ isLoading, bucket, verifyDns }: DomainProps) => {
  const classes = useStyles();

  if (isLoading) {
    return <DomainSkeleton />;
  }

  if (!bucket?.domain && !bucket?.domainStatus) {
    return null;
  }

  const domainName = get(bucket, "domain", "");

  return (
    <ListItem className={classes.domain}>
      <DomainInfo domain={domainName} domainStatus={bucket.domainStatus} />
      <DomainStatus
        domainStatus={bucket.domainStatus}
        verifyDns={verifyDns}
      />
      <DomainOptions domain={bucket.domain} />
    </ListItem>
  );
};

export default Domain;
