import { usePrevious } from "@fleekhq/react-drip";
import { useCallback, useEffect, useState } from "react";
import { GA_EVENTS_CATEGORIES } from "src/constants";
import { useGithubToken, useOctokit } from "src/hooks";
import { url } from "src/utils";

import { transformAccountToSelectorFormat } from "./utils";

export type MappedRepository = {
  installationId: number;
  account: {
    name?: string;
    avatar?: string;
  };
};

export type GithubSuccessOptions = {
  installationId?: number;
  accountName?: string;
  repositoryName?: string;
};

export type UseGithubOptions = {
  onError: (error?: Error) => void;
  onSuccess: (options: GithubSuccessOptions) => void;
};

export type ParsedRepository = {
  name: string;
  owner: string;
  fullName: string;
  url: string;
};

export const useGithub = ({ onError, onSuccess }: UseGithubOptions) => {
  const octokit = useOctokit();

  const [searchValue, setSearchValue] = useState("");

  const [accountList, setAccountList] = useState<MappedRepository[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<MappedRepository>();
  const [isInstallationsLoading, setIsInstallationsLoading] = useState(false);

  const previousSelectedAccount = usePrevious(selectedAccount);

  const [repositoryList, setRepositoryList] = useState<ParsedRepository[]>([]);
  const [isRepositoriesLoading, setIsRepositoriesLoading] = useState(false);

  const repositories = repositoryList.filter(
    ({ name }) => name.search(searchValue.trim() || name) !== -1
  );

  const [isGithubTokenLoading, githubToken] = useGithubToken();
  const [hasInstallations, setHasInstallations] = useState<boolean>();

  const loadInstallations = useCallback(async () => {
    if (githubToken && hasInstallations === undefined) {
      try {
        setIsInstallationsLoading(true);
        const { data } =
          await octokit.apps.listInstallationsForAuthenticatedUser({
            per_page: 100,
            v: new Date().getTime(),
          });

        const accounts = data.installations.map(({ id, account }) => ({
          installationId: id,
          account: {
            name: account?.login,
            avatar: account?.avatar_url,
          },
        }));

        setHasInstallations(true);
        setAccountList(accounts);
        setSelectedAccount(accounts[accounts.length - 1]);
      } catch (error: any) {
        if (error.status === 401) {
          setHasInstallations(false);
          onError();
        }
      } finally {
        setIsInstallationsLoading(false);
      }
    }
  }, [octokit.apps, githubToken, hasInstallations, onError]);

  const selectorOptions = accountList.map((account, index) =>
    transformAccountToSelectorFormat({
      repository: account,
      isSelected: selectedAccount
        ? account.account.name === selectedAccount.account.name
        : index === 0,
    })
  );

  const selectorValue = transformAccountToSelectorFormat({
    repository: selectedAccount,
    isSelected: true,
  });

  const refetchData = () => {
    if (!isInstallationsLoading) {
      loadInstallations();
    }
  };

  const submitAnalytics = (repository: any) => {
    window.ga(
      "send",
      "event",
      GA_EVENTS_CATEGORIES.SITES,
      ANALYTICS_LABEL,
      repository.url
    );
    window.analytics.track(ANALYTICS_LABEL, {
      url: repository.url,
      repositoryName: repository.name,
      accountName: repository.owner,
      teamId: url.getAccountIdFromUrl(),
    });
  };

  const handleSelectRepository = (repository: any) => {
    submitAnalytics(repository);

    onSuccess({
      installationId: selectedAccount?.installationId,
      accountName: selectedAccount?.account?.name,
      repositoryName: repository.name,
    });
  };

  const fetchRepos = useCallback(async () => {
    setRepositoryList([]);

    const options =
      octokit.apps.listInstallationReposForAuthenticatedUser.endpoint.merge({
        installation_id: selectedAccount?.installationId,
        per_page: 100,
        v: new Date().getTime(),
      });

    const allFetchedRepos: any[] = [];
    const onEveryResponse = ({ data }: { data: any[] }) => {
      const fetchedRepos = data.map((repo) => ({
        name: repo.name,
        owner: repo.owner.login,
        fullName: repo.full_name,
        url: repo.url,
      }));

      allFetchedRepos.push(...fetchedRepos);
    };

    try {
      setIsRepositoriesLoading(true);
      await octokit.paginate(options as any, onEveryResponse);
      setRepositoryList(allFetchedRepos);
    } catch (error) {
      console.error("Octokit Repositories Error: ", error);
    } finally {
      setIsRepositoriesLoading(false);
    }
  }, [selectedAccount?.installationId, octokit]);

  const handleChangeAccount = (
    value: ReturnType<typeof transformAccountToSelectorFormat>
  ) => {
    const account = accountList.find(
      ({ account }) => value.name === account.name
    );

    setSelectedAccount(account);
  };

  useEffect(() => {
    const shouldFetchInstallations =
      !isInstallationsLoading &&
      !isGithubTokenLoading &&
      accountList.length === 0 &&
      !selectedAccount && 
      hasInstallations === undefined;

    if (shouldFetchInstallations) {
      loadInstallations();
    } else if (!isGithubTokenLoading && !githubToken) {
      onError();
    }
  }, [
    isGithubTokenLoading,
    githubToken,
    loadInstallations,
    onError,
    isInstallationsLoading,
    accountList.length,
    selectedAccount,
    hasInstallations
  ]);

  useEffect(() => {
    const shouldFetchRepos =
      selectedAccount?.installationId &&
      !isRepositoriesLoading &&
      !isGithubTokenLoading &&
      previousSelectedAccount?.installationId !==
        selectedAccount.installationId;

    if (shouldFetchRepos) {
      fetchRepos();
    }
  }, [
    fetchRepos,
    isGithubTokenLoading,
    isRepositoriesLoading,
    previousSelectedAccount?.installationId,
    selectedAccount?.installationId,
  ]);

  return {
    onSelectRepository: handleSelectRepository,
    onChangeAccount: handleChangeAccount,
    refetchData,
    setSearchValue,
    selectorValue,
    selectorOptions,
    selectedAccount,
    searchValue,
    repositories,
    hasInstallations,
    setHasInstallations,
    isLoading:
      isRepositoriesLoading || isGithubTokenLoading || isInstallationsLoading,
  };
};

const ANALYTICS_LABEL = "Pick example Repo";
