import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Breadcrumbs, MainContent, Overlay, OVERLAY_TYPES } from "src/components";
import { useTeam } from "src/store/AuthContext";
import { url } from "src/utils";

import AddMember from "./AddMember";
import { ChangePlan, GeneralBilling } from "./Billing";
import CreateTeam from "./CreateTeam";
import Members from "./Members";
import TeamSites from "./Sites";
import Storage from "./Storage";

const Teams = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const { canUseStorage, migrationStatus } = useTeam();

  const breadcrumbs = <Breadcrumbs />;

  return (
    <MainContent topBarContent={breadcrumbs}>
      <Helmet>
        <title>{t("teams.pageTitle")}</title>
      </Helmet>
      <Overlay
        type={OVERLAY_TYPES.MIGRATED_TEAM}
        isContentActive={!migrationStatus}
      />
      <Switch>
        <Route path={`${match.path}/create`}>
          <CreateTeam />
        </Route>
        <Route path={`${match.path}/:teamId/sites`}>
          <TeamSites />
        </Route>
        <Route path={`${match.path}/:teamId/billing/general/:section?`}>
          <GeneralBilling />
        </Route>
        <Route path={`${match.path}/:teamId/billing/change-plan`}>
          <ChangePlan />
        </Route>
        <Route path={`${match.path}/:teamId/members/add`}>
          <AddMember />
        </Route>
        <Route path={`${match.path}/:teamId/members`}>
          <Members />
        </Route>
        <Route path={`${match.path}/:teamId/storage`}>
          {canUseStorage ? <Redirect to={url.buildUrl(null, "/error/404")} /> : <Storage />}
        </Route>
      </Switch>
    </MainContent>
  );
};

export default Teams;
