import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '3px 0 15px 0',
  },
  key: {
    minWidth: 202,
  },
  editIconAndTextContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  item: {
    height: 46,
    padding: '0 20px',
    alignItems: 'center',
  },
  editIcon: {
    marginLeft: 5,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  submitButton: {
    margin: '0px 15px 19px',
  },
  text: {
    width: '100%',
    display: 'flex',
    margin: '10px 0',
    justifyContent: 'center',
  }
});
